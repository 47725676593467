/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import { useQuery } from 'utils/react-query';
import dynamic from 'next/dynamic';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Menu from 'components/uiLibrary/Menu';
import Drawer from 'components/uiLibrary/Drawer';
import Logout from 'components/Globals/ProfileDropdown/Logout';
import Badge from 'components/uiLibrary/Badge';
import IconCard from 'components/uiLibrary/IconCard';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import ProfileMenuButton from 'components/Globals/ProfileDropdown/ProfileMenuButton';
import Profile from 'components/Globals/ProfileDropdown/Profile';

import queries from 'containers/Accounts/queries';

import { route } from 'constants/routes';
import {
  TP,
  ARTIST_TYPE_ID,
  AUDIENCE_TYPE_ID,
  ACADEMICS_TYPE_ID,
  JOURNALIST_TYPE_ID,
  AGENCY_TYPE_ID,
  COMPANY_TYPE_ID,
  TABS_TO_SHOW,
} from 'constants/index';
import { PROFILE_SWITCH } from 'constants/cookieConstants';

import {
  // getGiftSubscriptionEnabled,
  showNotificationIcon,
  getProfileTypeEditLabel,
  getUpdateProfilePath,
} from 'utils/profileDropdown';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import {
  useUserData,
  useActiveProfileData,
  useUserProfiles,
  // useActiveProfileSubscriptions,
  useAccessPendingProfileIds,
} from 'utils/hooks/useAuthenticatedUser';
import usePermissions from 'utils/permissions';
import { useSubscription } from 'utils/hooks/useSubscription';
import { useProfileNotificationCounts } from 'utils/queries/notificationCounts';
import { setCookie } from 'utils/cookie';
import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS } from 'utils/tracking';

import { useTranslation, i18n, Router } from 'src/i18n';

import classes from './ProfileDropdown.module.scss';

const NotificationsPopup = dynamic(() => import('components/Notifications/NotificationsPopUp'));
const LanguageSelector = dynamic(() => import('components/Globals/LanguageSelector'));
const AllProfiles = dynamic(() => import('components/Globals/ProfileDropdown/AllProfiles'));
const SetupAccount = dynamic(() => import('components/Globals/ProfileDropdown/SetupAccount'));

const trackingData = {
  section: SECTIONS.PROFILE_DROPDOWN,
};

const ProfileDropdown = ({
  hideActions = false,
  currentTarget,
  onClose,
  currentActiveTab = '',
  hideBackButton = false,
}) => {
  const router = useRouter();
  const { navigate } = usePageContext();
  const { t } = useTranslation();
  const track = useNewTracking();
  const subscription = useSubscription();
  const { setIsOpenActionCenter } = useDialogs();

  const { profileNotificationsCount } = useProfileNotificationCounts();
  const profiles = useUserProfiles();
  const [profileDropDownState, setProfileDropDownState] = useState(currentTarget || null);
  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const [activeSection, setActiveSection] = useState(null);

  const activeProfile = useActiveProfileData();
  const userData = useUserData();
  const profileIdsPendingAccess = useAccessPendingProfileIds();
  // const activeProfileSubscriptions = useActiveProfileSubscriptions();
  const { isAdmin } = usePermissions(activeProfile?.id);

  const hasCompanyLinked =
    activeProfile?.organizations?.[0]?.organization?.id && activeProfile?.profileType?.id === COMPANY_TYPE_ID;
  const hasAgencyLinked = activeProfile?.agencies?.[0]?.id && activeProfile?.profileType?.id === AGENCY_TYPE_ID;
  const hasProfileLinked = activeProfile?.profileType?.id === ARTIST_TYPE_ID;

  const activeProfilePath = navigate.getLinkProps(getUpdateProfilePath(activeProfile, subscription));
  const updateButtonLabel = getProfileTypeEditLabel(activeProfile?.profileType?.id);
  // const isGiftSubscriptionEnabled = getGiftSubscriptionEnabled({
  //   profileSubscriptions: activeProfileSubscriptions,
  //   profile: activeProfile,
  // });
  const hasRegistrationRoute = router.route === route.REGISTER;
  const showNotification = showNotificationIcon(activeProfile) && !hasRegistrationRoute;

  const { data: registrations, refetch: refetchRegistration } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: !!userData?.id && Boolean(profileDropDownState) },
    }),
  );

  const activeRegistration = useMemo(
    () => find(registrations?.data, reg => reg?.entities?.profile?.id === activeProfile?.id),
    [activeProfile.id, registrations],
  );
  useEffect(() => {
    if (profileDropDownState) {
      refetchRegistration();
    }
  }, [refetchRegistration, userData, activeProfile, profileDropDownState]);

  const isAosPageTypeLinked =
    (activeProfile?.profileType?.id === activeRegistration?.entities?.profile?.profileType?.id &&
      activeRegistration?.entities?.organization?.id) ||
    activeProfile?.organizations?.length > 0 ||
    false;
  const isAgencyLinked =
    (activeProfile?.profileType?.id === activeRegistration?.entities?.profile?.profileType?.id &&
      activeRegistration?.entities?.agency?.id) ||
    false;

  const isArtistProfile = activeProfile.profileType?.id === ARTIST_TYPE_ID;
  const isAudienceProfile = activeProfile.profileType?.id === AUDIENCE_TYPE_ID;
  const isAcademicsProfile = activeProfile.profileType?.id === ACADEMICS_TYPE_ID;
  const isJournalistProfile = activeProfile.profileType?.id === JOURNALIST_TYPE_ID;

  const isProfileTypeLinked =
    isAosPageTypeLinked ||
    isAgencyLinked ||
    isArtistProfile ||
    isAudienceProfile ||
    isAcademicsProfile ||
    isJournalistProfile;

  const { isMobile } = useDeviceTypeLayouts();

  const handleBack = () => {
    setActiveTab('');

    if (activeTab) {
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: PROFILE_DROPDOWN_EVENT_IDS?.BACK?.[activeTab],
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  };

  const handleCloseMenu = () => {
    setProfileDropDownState(null);
    setActiveSection(null);
    handleBack();
    if (onClose) {
      onClose();
    }
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_CLOSE,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const handleOpenMenu = event => {
    setProfileDropDownState(event.currentTarget);
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.VIEW_CTA,
    });
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_OPEN,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const editProfileHandler = ({ profileId, path }) => {
    setCookie(PROFILE_SWITCH, true);
    window.location.href = `/auth/switchProfile?profileId=${profileId}&pageUrl=${path}&lang=${i18n.language}`;
    handleCloseMenu();
  };

  const middleSectionConfig = [
    {
      icon: 'manage-switch',
      title: t(`${TP}.MANAGE_PROFILE`),
      onClick: () => {
        setActiveTab(TABS_TO_SHOW.PROFILE_SELECTOR);
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_MANAGE,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.MANAGE_BUTTON },
      enabled: profiles.length > 0,
    },
    {
      icon: 'action_center',
      title: t(`${TP}.FN_ACTION_CENTER_TITLE`),
      onClick: () => {
        setIsOpenActionCenter(true);
        handleCloseMenu();
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_ACTION_CENTER,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.ACTION_CENTER_CTA },
      enabled: true,
    },
    // TODO: Hiding referral flow to fix in future
    // {
    //   icon: 'gift_icon',
    //   title: (
    //     <>
    //       <Trans
    //         i18nKey={t(`${TP}.GIFT_SUBSCRIPTION_CREDIT_TITLE`)}
    //         components={{
    //           b: <span className={classes.price} />,
    //         }}
    //       />
    //     </>
    //   ),
    //   onClick: () => {
    //     Router.push(`${route.ACCOUNT_SETTINGS}/?settings=gift_subscriptions`);
    //     track.click(
    //       {
    //         name: TRACK_EVENTS.CLICK,
    //         data: {
    //           id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_GIFT_SUBSCRIPTION,
    //         },
    //       },
    //       GOOGLE_OLD_TRACKING_SERVICES,
    //     );
    //   },
    //   trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.SUBSCRIPTION_GIFT_CTA },
    //   enabled: isGiftSubscriptionEnabled,
    // },
    {
      icon: 'world',
      title: t(`${TP}.SELECT_LANGUAGE`),
      onClick: () => {
        setActiveTab(TABS_TO_SHOW.LANGUAGE_SELECTOR);
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_LANGUAGE_SELECTOR,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.LANGUAGE_SELECTOR_CTA },
      enabled: true,
    },
    {
      icon: 'settings',
      title: t(`${TP}.ACCOUNT_SETTINGS_GENERAL_TITLE`),
      onClick: () => {
        Router.push(route.ACCOUNT_SETTINGS);
        handleCloseMenu();
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_ACCOUNT_SETTINGS,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.ACCOUNT_SETTINGS_CTA },
      enabled: true,
    },
    {
      icon: 'support-agent',
      title: t(`${TP}.FN_CONTACT_US`),
      onClick: () => {
        Router.push(route.CONTACT_OB);
        handleCloseMenu();
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_CONTACT_US,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.CONTACT_CTA },
      enabled: true,
    },
  ];

  const profileDropdownContent = (
    <div className={classes.profileDropdown__content}>
      <div className={classes.profileDropdown__topSection}>
        {isProfileTypeLinked ? (
          <Profile
            profile={activeProfile}
            onClick={editProfileHandler}
            className={classes.profileDropdown__profileSection_profile}
            profileIdsPendingAccess={profileIdsPendingAccess} // Need to use for profile access
            activeProfilePath={activeProfilePath}
            updateButtonLabel={updateButtonLabel}
            setProfileDropDownState={setProfileDropDownState}
            trackingData={trackingData}
          />
        ) : (
          <SetupAccount key="profile_setup" registration={activeRegistration} trackingData={trackingData} />
        )}
        {showNotification && (
          <div className={classes.profileDropdown__topSection__quickActions}>
            <IconCard
              icon="notifications_black_24dp"
              title={
                <div className={classes.notificationsTitle}>
                  {profileNotificationsCount !== 0 && (
                    <Badge
                      className={classes.notificationsTitle_badge}
                      content={profileNotificationsCount}
                      variant="secondary"
                      size="8"
                    />
                  )}
                  <span>{t(`${TP}.NOTIFICATIONS`)}</span>
                </div>
              }
              showCircle={false}
              style={classes.iconCardWrapper}
              onClick={() => {
                setActiveTab(TABS_TO_SHOW.NOTIFICATIONS);
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_NOTIFICATION,
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.NOTIFICATIONS_CTA }}
              isHover
            />
          </div>
        )}
      </div>
      <div className={classes.profileDropdown__middleSection}>
        {middleSectionConfig.map(
          item =>
            item.enabled && (
              <IconCard
                key={item.title}
                icon={item.icon}
                title={item.title}
                showCircle={false}
                style={classes.iconCardWrapper}
                onClick={item.onClick}
                isHover
                trackingData={item.trackingData}
              />
            ),
        )}
      </div>
      <Logout key="logout" user={userData} trackingData={trackingData} />
    </div>
  );

  const BackHeader = ({ title }) => (
    <div className={classes.backHeader}>
      {!hideBackButton && (
        <SpriteIcon className={classes.backHeader_backBtn} icon="arrow_back" size={16} onClick={handleBack} />
      )}
      <Typography size={16} weight="bold" className={classes.backHeader__title}>
        {title}
      </Typography>
    </div>
  );

  const ActiveContent = () => {
    const getBackHeader = title => <BackHeader title={title} />;

    const tabContentMap = {
      [TABS_TO_SHOW.PROFILE_SELECTOR]: {
        title: t(`${TP}.MANAGE_PROFILE`),
        content: <AllProfiles registrations={registrations} />,
      },
      [TABS_TO_SHOW.LANGUAGE_SELECTOR]: {
        title: t(`${TP}.SELECT_LANGUAGE`),
        content: (
          <div className={classes.languageSelectorWrapper}>
            <LanguageSelector renderAs="content" isProfileDropdown />
          </div>
        ),
      },
      [TABS_TO_SHOW.NOTIFICATIONS]: {
        title: t(`${TP}.NOTIFICATIONS`),
        content: <NotificationsPopup activeProfile={activeProfile} renderAs="content" />,
      },
    };

    const { title, content } = tabContentMap[activeTab] || { title: '', content: profileDropdownContent };

    return (
      <div>
        {title && getBackHeader(title)}
        {content}
      </div>
    );
  };

  return (
    <div className={classes.profileDropdown}>
      {!hideActions && (
        <div className={classes.profileDropdown__actions} onClick={handleOpenMenu}>
          {activeProfilePath.as &&
            (hasAgencyLinked || hasCompanyLinked || hasProfileLinked) &&
            !hasRegistrationRoute &&
            (isMobile ? (
              <SpriteIcon icon="pencil" />
            ) : (
              <Typography size={14} weight="medium" className={classes.profileDropdown__actions_profileTypeEditTitle}>
                {t(updateButtonLabel)}
              </Typography>
            ))}
          <div className={classes.profileDropdown__actions__activeContainer}>
            <div className={classes.profileDropdown__actions__activeWrap}>
              {showNotification && (
                <div className={classes.profileDropdown__actions_notificationIcon}>
                  <NotificationsNoneIcon className={!profileNotificationsCount ? classes.notificationIcon : null} />
                  {profileNotificationsCount !== 0 && (
                    <Badge content={profileNotificationsCount} variant="secondary" size="8" />
                  )}
                </div>
              )}
              <ProfileMenuButton
                profile={activeProfile}
                isAdmin={isAdmin}
                user={userData}
                showNotification={showNotification}
              />
            </div>
            {Boolean(profileDropDownState) && <div className={classes.profileDropdown__actions__activeBorder}></div>}
          </div>
        </div>
      )}
      {!isMobile ? (
        <Menu
          anchorEl={profileDropDownState}
          isOpen={Boolean(profileDropDownState)}
          onClose={handleCloseMenu}
          className={classes.profileDropdown__menu}
        >
          <ActiveContent />
        </Menu>
      ) : (
        <Drawer
          isOpen={Boolean(profileDropDownState)}
          onClose={handleCloseMenu}
          className={classes.profileDropdown__drawer}
        >
          {activeSection && (
            <div className={classes.profileDropdown__title} onClick={handleCloseMenu}>
              <SpriteIcon icon="close" size={17} />
            </div>
          )}
          <ActiveContent />
        </Drawer>
      )}
    </div>
  );
};

export default ProfileDropdown;
